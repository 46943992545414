<!--
 * @名称: 检测详情 不包含车辆基础信息 兼容PC和mobile
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-16 11:45:36
 * @LastEditors  : min
 * @LastEditTime : 2022-11-30 10:33:49
 -->
<template>
  <div class="ml_detectiondetail">
    <template v-if="isMobile">
      <!-- <van-sticky>
        <van-tabbar active-color="#ff7901" inactive-color="#858585" v-model="active" @change="onChange" :fixed="false" shrink>
          <van-tabbar-item>
            外观
            <template v-if="wgcount">({{wgcount}})</template>
          </van-tabbar-item>
          <van-tabbar-item>
            骨架
            <template v-if="gjcount">({{gjcount}})</template>
          </van-tabbar-item>
          <van-tabbar-item>
            内饰
            <template v-if="nscount">({{nscount}})</template>
          </van-tabbar-item>
          <van-tabbar-item>
            机电
            <template v-if="jdcount">({{jdcount}})</template>
          </van-tabbar-item>
          <van-tabbar-item>
            水渍残留点
            <template v-if="szclcount">({{szclcount}})</template>
          </van-tabbar-item>
        </van-tabbar>
      </van-sticky> -->

      <div class="ml_tabbar_list">
        <div class="ml_tabbar_list_item" @click="onChange(0)">
          <span :class="{'ml_tabbar_list_item_active':tabbarIndex==0}">
            外观
            <template v-if="wgcount">({{wgcount}})</template>
          </span>
        </div>
        <div class="ml_tabbar_list_item" @click="onChange(1)">
          <span :class="{'ml_tabbar_list_item_active':tabbarIndex==1}">
            骨架
            <template v-if="gjcount">({{gjcount}})</template></span>
        </div>
        <div class="ml_tabbar_list_item" @click="onChange(2)">
          <span :class="{'ml_tabbar_list_item_active':tabbarIndex==2}">
            内饰
            <template v-if="nscount">({{nscount}})</template>
          </span>
        </div>
        <div class="ml_tabbar_list_item" @click="onChange(3)">
          <span :class="{'ml_tabbar_list_item_active':tabbarIndex==3}">
            机电
            <template v-if="jdcount">({{jdcount}})</template>
          </span>
        </div>
        <div class="ml_tabbar_list_item" @click="onChange(4)">
          <span :class="{'ml_tabbar_list_item_active':tabbarIndex==4}">
            水渍残留点
            <template v-if="szclcount">({{szclcount}})</template>
          </span>
        </div>
      </div>

      <div style="height:calc(100vh - 50px); overflow: auto;-webkit-overflow-scrolling: touch;">
        <!-- 检测报告 1.0 -->
        <detectionReport :reportData="reportData" v-if="reportVersion===1"></detectionReport>

        <!-- 检测报告 2.0 -->
        <detectionReport2 :reportData="reportData" v-if="reportVersion===2"></detectionReport2>
      </div>
    </template>

    <template v-else>
      <!-- 检测报告 1.0 -->
      <detectionReportPC :reportData="reportData" v-if="reportVersion===1"></detectionReportPC>

      <!-- 检测报告 2.0 -->
      <detectionReportPC2 :reportData="reportData" v-if="reportVersion===2"></detectionReportPC2>

    </template>

    <van-popup v-model="showLoading" :close-on-click-overlay="false" class="ml_popu_loading">
      <van-loading size="24px" vertical type="spinner">
        <span style="color:#fff;">
          加载中...
        </span>
      </van-loading>
    </van-popup>
  </div>
</template>
<script>
import axios from 'axios'

import detectionReport from '@/components/component_mobile/component_detectionReport'
import detectionReport2 from '@/components/component_mobile/component_detectionReport_2.0'

import detectionReportPC from '@/components/component_pc/component_detectionReport_pc'
import detectionReportPC2 from '@/components/component_pc/component_detectionReport_pc_2.0'

export default {
  components: {
    detectionReport,
    detectionReport2,
    detectionReportPC,
    detectionReportPC2
  },
  data() {
    let _isMobile = false

    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      _isMobile = true
    } else {
      _isMobile = false
    }

    return {
      json: '',
      active: 0,
      reportData: null,
      isMobile: _isMobile,
      showLoading: false,
      reportVersion: null,


      wgcount: '',
      gjcount: '',
      nscount: '',
      jdcount: '',
      szclcount: '',
      tabbarIndex: 0,
    }
  },
  mounted() {
    this.json = decodeURIComponent(this.$route.query.url)
    this.getDetectionDetail()
  },
  methods: {
    onClickLeft() {
      if (this.$route.query.uid) {
        location.href = 'carwins://go?back'
      } else {
        this.$router.back()
      }
    },
    onChange(i) {
      this.tabbarIndex = i;
      switch (i) {
        case 0:
          document.querySelector('#Element001').scrollIntoView(true)
          break
        case 1:
          document.querySelector('#Element002').scrollIntoView(true)
          break
        case 2:
          document.querySelector('#Element003').scrollIntoView(true)
          break
        case 3:
          document.querySelector('#Element004').scrollIntoView(true)
          break
        case 4:
          document.querySelector('#Element005').scrollIntoView(true)
          break
      }
    },
    getDetectionDetail() {
      const that = this
      that.showLoading = true
      axios
        .get(this.json)
        .then(function (res) {
          that.reportData = res.data

          that.reportData.data.forEach(element => {
            switch (element.part_code) {
              case 'P01':
                // 骨架
                that.gjcount = element.e_count
                break
              case 'P02':
                // 外观
                that.wgcount = element.e_count
                break
              case 'P03':
                // 内饰
                that.nscount = element.e_count
                break
              case 'M013':
                // 机电
                that.jdcount = element.e_count
                break
              case '-1':
                // 水渍残留点
                that.szclcount = element.e_count
                break
            }
          })


          if (that.reportData) {
            if (that.reportData.version === '3.0') {
              that.reportVersion = 2
            } else if (that.reportData.version === '2.0') {
              that.reportVersion = 2
            } else {
              that.reportVersion = 1
            }
          } else {
            that.reportVersion = 1
          }

          setTimeout(() => {
            that.showLoading = false
          }, 500)
        })
    }
  }
}
</script>
<style lang="scss">
.ml_detectiondetail {
  // padding-top: 46px;

  .ml_popu_loading {
    background-color: transparent;
    text-align: center;
  }

  .ml_detection_mark {
    padding: 15px;

    .ml_detection_mark_content {
      padding: 16px;
      color: #9a9a9a;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    }
  }

  .ml_detection_line {
    border-bottom: 10px solid #f2f2f2;
  }

  .van-collapse-item__content {
    padding: 0;
  }

  .van-tag--default.van-tag--plain {
    margin: 0 10px 10px 0;
  }

  .custom-title {
    color: #6c6c6c;
  }

  .van-popup--center {
    width: 100%;
  }

  .custom-title-car {
    font-size: 16px;
    font-weight: bold;
    padding-left: 4px;
  }
  .custom-label {
    padding: 16px;
    margin-top: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .van-image-preview__cover {
    bottom: 10px;
    color: #fff;
    left: 0;
    width: 100%;
    text-align: center;
    top: unset;
  }
  .van-swipe-item {
    position: relative;

    .ml_detection_name {
      text-align: center;
      padding: 5px 0;
    }

    .ml_dot_detection {
      position: absolute;
      z-index: 9999;

      .ml_dot_color {
        color: #797979;
        &::before {
          //   content: "●";
          font-size: 14px;
          padding-right: 8px;
        }
      }

      .ml_dot_triangle {
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 13px solid transparent;
          border-right: 13px solid rgba(0, 0, 0, 0.8);
          border-bottom: 13px solid transparent;
          display: inline-block;
        }
      }

      .ml_dot_text {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
        height: 26px;
        line-height: 26px;
        display: inline-block;
        white-space: nowrap;
        padding: 0 8px;
        margin-left: 13px;
      }
    }
  }

  .ml_tabbar_list {
    // display: flex;
    // text-align: center;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 99;
    white-space: nowrap;
    overflow-x: auto;

    .ml_tabbar_list_item {
      // flex: 1;
      padding: 10px;
      word-break: keep-all;
      font-size: 14px;
      display: inline-block;
    }
    .ml_tabbar_list_item_active {
      padding: 10px 0;
      border-bottom: 1px solid #ff7901;
      color: #ff7901;
    }

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 0; /*高宽分别对应横竖滚动条的尺寸*/
      height: 0;
    }
  }
}
</style>
